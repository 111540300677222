<template>
  <a-modal width="520px" :visible="visible" @ok="handleOk" @cancel="handleCancel">
    <span slot="title"
      ><a-icon :style="{ color }" type="info-circle" /><span style="margin-left:10px">请选择同步方式</span></span
    >
    <a-form :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }">
      <!--       <a-form-item v-if="syncType == 'GLOBAL'" label="未匹配到时新建">
        <a-radio-group
          :options="[
            { label: '是', value: true },
            { label: '否', value: false }
          ]"
          v-model="isForce"
        />
      </a-form-item> -->
      <a-form-item label="同步作品">
        <a-tree-select
          v-model="productIds"
          dropdownClassName="sync-tree"
          treeDefaultExpandAll
          style="width: 260px"
          :tree-data="productList"
          allowClear
          :maxTagCount="2"
          :replaceFields="{ children: 'childrenList', title: 'showName' }"
          tree-checkable
          placeholder="请选择作品"
        />
      </a-form-item>
      <a-form-item label="同步阶段">
        <a-radio-group  v-model="ischeck">
          <a-radio value="YES">是</a-radio>
          <a-radio value="NO">否</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="同步规则">
        <a-tree-select
          v-model="stageRuleIds"
          dropdownClassName="sync-tree"
          treeDefaultExpandAll
          style="width: 260px"
          :tree-data="ruleList"
          allowClear
          :maxTagCount="2"
          :replaceFields="{ title: 'name', key: 'id', value: 'id' }"
          tree-checkable
          placeholder="请选择规则"
        />
      </a-form-item>
      <!--       <a-form-item label="规则内容">
        <a-checkbox-group v-model="ruleCheckedList" style="width:260px">
          <a-row>
            <a-col :span="12" style="position: relative;top: 5px;">
              <a-checkbox value="hasRuleBaseInfo">
                基础信息
              </a-checkbox>
            </a-col>
            <a-col :span="12" style="position: relative;top: 5px;">
              <a-checkbox value="hasRuleContent">
                正文
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-form-item> -->
    </a-form>
  </a-modal>
</template>

<script>
import { postAction } from '@/api/manage'
import { getCurUserDepartTree } from '@/api/productTree'
export default {
  props: {
    syncType: {
      type: String,
      default: 'GLOBAL'
    },
    url: {
      type: String,
      default: ''
    },
    selectedRowKeys: {
      type: Array,
      default: () => []
    },
    selectionRows: {
      type: Array,
      default: () => []
    },
    idType: {
      type: String,
      default: 'ids'
    },
    platformId: [Number, String]
  },
  data() {
    return {
      visible: false,
      ischeck:'YES',
      color: '',
      productList: [],
      isForce: true,
      checkedList: ['hasBaseInfo'],
      ruleCheckedList: ['hasRuleContent', 'hasRuleBaseInfo'],
      treeData: [],
      spinning: false,
      productName: '',
      productIds: [],
      stageRuleIds: [],
      ruleList: []
    }
  },
  created() {},
  mounted() {
    this.color = this.$store.state.app.color
  },
  methods: {
    async open() {
      this.visible = true
      this.ruleList = this.selectionRows[0].ruleList
      const res = await postAction('/production/query', [this.platformId])
      if (res.code == 200) {
        this.productList = []
        this.productList.push({
          key: res.data[0].platformId,
          showName: res.data[0].platformName || '',
          isPlatform: true,
          value: res.data[0].platformId,
          childrenList: []
        })
        this.productList.forEach(item => {
          res.data.forEach(i => {
            if (item.value == i.platformId) {
              item.childrenList.push({ showName: i.productionName, key: i.productionId, value: i.productionId })
            }
          })
        })
      } else {
        this.$message.error(res.msg || res.message)
      }
    },
    async handleOk() {
      if (!this.url) return
      let params = {
        hasBaseInfo: this.ischeck=='YES',
        hasRuleContent: this.ruleCheckedList.some(item => item == 'hasRuleContent'),
        hasRuleBaseInfo: this.ruleCheckedList.some(item => item == 'hasRuleBaseInfo'),
        productIds: this.productIds,
        stageRuleIds: this.stageRuleIds,
        hasContent: true
      }
      params[this.idType] = this.selectedRowKeys
      const res = await postAction(this.url, params)
      if (res.code == 200) {
        this.$message.success('同步成功')
        this.$emit('loadData')
      } else {
        this.$message.error(res.message || res.msg)
      }
      this.visible = false
    },
    handleCancel() {
      this.productIds = []
      this.checkedList = ['hasBaseInfo']
      this.stageRuleIds = []
      this.ruleCheckedList = ['hasRuleContent', 'hasRuleBaseInfo']
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped></style>
