var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: { width: "520px", visible: _vm.visible },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "span",
        { attrs: { slot: "title" }, slot: "title" },
        [
          _c("a-icon", {
            style: { color: _vm.color },
            attrs: { type: "info-circle" },
          }),
          _c("span", { staticStyle: { "margin-left": "10px" } }, [
            _vm._v("请选择同步方式"),
          ]),
        ],
        1
      ),
      _c(
        "a-form",
        { attrs: { "label-col": { span: 10 }, "wrapper-col": { span: 14 } } },
        [
          _c(
            "a-form-item",
            { attrs: { label: "同步作品" } },
            [
              _c("a-tree-select", {
                staticStyle: { width: "260px" },
                attrs: {
                  dropdownClassName: "sync-tree",
                  treeDefaultExpandAll: "",
                  "tree-data": _vm.productList,
                  allowClear: "",
                  maxTagCount: 2,
                  replaceFields: {
                    children: "childrenList",
                    title: "showName",
                  },
                  "tree-checkable": "",
                  placeholder: "请选择作品",
                },
                model: {
                  value: _vm.productIds,
                  callback: function ($$v) {
                    _vm.productIds = $$v
                  },
                  expression: "productIds",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "同步阶段" } },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.ischeck,
                    callback: function ($$v) {
                      _vm.ischeck = $$v
                    },
                    expression: "ischeck",
                  },
                },
                [
                  _c("a-radio", { attrs: { value: "YES" } }, [_vm._v("是")]),
                  _c("a-radio", { attrs: { value: "NO" } }, [_vm._v("否")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "同步规则" } },
            [
              _c("a-tree-select", {
                staticStyle: { width: "260px" },
                attrs: {
                  dropdownClassName: "sync-tree",
                  treeDefaultExpandAll: "",
                  "tree-data": _vm.ruleList,
                  allowClear: "",
                  maxTagCount: 2,
                  replaceFields: { title: "name", key: "id", value: "id" },
                  "tree-checkable": "",
                  placeholder: "请选择规则",
                },
                model: {
                  value: _vm.stageRuleIds,
                  callback: function ($$v) {
                    _vm.stageRuleIds = $$v
                  },
                  expression: "stageRuleIds",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }