var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: _vm.backCss },
    [
      _c(
        "div",
        { staticClass: "actions flex jc-end mb-4" },
        [
          _vm.selectedRowKeys.length && _vm.parent.isTongChou
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "platform:stage:button:delete",
                      expression: "'platform:stage:button:delete'",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: { type: "danger", icon: "delete" },
                  on: { click: _vm.batchDel },
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
          _vm.selectedRowKeys.length
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "system:rule:list:button:add",
                      expression: "'system:rule:list:button:add'",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: {
                    type: "primary",
                    disabled:
                      _vm.selectedRowKeys.length >= 2 ||
                      !_vm.selectionRows.length,
                    icon: "cloud",
                  },
                  on: { click: _vm.pSync },
                },
                [_vm._v("同步")]
              )
            : _vm._e(),
          _vm.parent.isTongChou
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "platform:stage:button:add",
                      expression: "'platform:stage:button:add'",
                    },
                  ],
                  attrs: { type: "primary", icon: "plus" },
                  on: { click: _vm.openAddForm },
                },
                [_vm._v("添加")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("a-table", {
        directives: [
          {
            name: "has",
            rawName: "v-has",
            value: "platform:stage:page:view",
            expression: "'platform:stage:page:view'",
          },
        ],
        ref: "table",
        staticStyle: { "border-top": "1px solid rgb(232,232,232)" },
        attrs: {
          size: "middle",
          rowKey: "id",
          columns: _vm.columns,
          dataSource: _vm.dataSource,
          customRow: _vm.clickRow,
          pagination: _vm.ipagination,
          scroll: { x: 1000, y: 500 },
          loading: _vm.loading,
          rowSelection: {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
            columnWidth: 90,
          },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "reversible",
            fn: function (text, record) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("bool")(record.reversible)) +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "evaluate",
            fn: function (text, record) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("bool")(record.ruleId > 0)) +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "rate",
            fn: function (text, record) {
              return [
                _vm._v(
                  "\n      " + _vm._s(_vm._f("bool")(record.rate)) + "\n    "
                ),
              ]
            },
          },
          {
            key: "costTime",
            fn: function (text, record) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      record.predefinedTime != null ||
                        record.predefinedTime != undefined
                        ? record.predefinedTime + " 天"
                        : ""
                    ) +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "ruleId",
            fn: function (text, record) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(record.ruleId > 0 ? "规则" + record.ruleId : "无") +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "ruleList",
            fn: function (text, record) {
              return _vm._l(record.ruleList, function (one) {
                return _c(
                  "a-tag",
                  {
                    key: one.id,
                    attrs: { color: one.hasUpdated ? "green" : "" },
                  },
                  [_vm._v(_vm._s(one.name))]
                )
              })
            },
          },
          {
            key: "rejectList",
            fn: function (text, record) {
              return _vm._l(record.rejectList, function (one) {
                return _c("a-tag", { key: one.stageId }, [
                  _vm._v("\n        " + _vm._s(one.stageName) + "\n      "),
                ])
              })
            },
          },
          {
            key: "kpiList",
            fn: function (text, record) {
              return _vm._l(record.kpiList, function (one) {
                return _c("a-tag", { key: one.stageId }, [
                  _vm._v("\n        " + _vm._s(one.stageName) + "\n      "),
                ])
              })
            },
          },
          {
            key: "applyTranslation",
            fn: function (text, record) {
              return _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c("a-switch", {
                    directives: [
                      {
                        name: "has",
                        rawName: "v-has",
                        value: "platform:stage:button:add",
                        expression: "'platform:stage:button:add'",
                      },
                    ],
                    on: { change: _vm.saveTableData },
                    model: {
                      value: record.applyTranslation,
                      callback: function ($$v) {
                        _vm.$set(record, "applyTranslation", $$v)
                      },
                      expression: "record.applyTranslation",
                    },
                  }),
                ],
                1
              )
            },
          },
          {
            key: "applyReview",
            fn: function (text, record) {
              return _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c("a-switch", {
                    directives: [
                      {
                        name: "has",
                        rawName: "v-has",
                        value: "platform:stage:button:add",
                        expression: "'platform:stage:button:add'",
                      },
                    ],
                    on: { change: _vm.saveTableData },
                    model: {
                      value: record.applyReview,
                      callback: function ($$v) {
                        _vm.$set(record, "applyReview", $$v)
                      },
                      expression: "record.applyReview",
                    },
                  }),
                ],
                1
              )
            },
          },
        ]),
      }),
      !_vm.isAdd
        ? _c("stage-modal", {
            attrs: {
              platformId: _vm.parent.id,
              open: _vm.drawerShow,
              "cur-row": _vm.curRow,
              isForm: _vm.isNewForm,
              parent: _vm.parent,
            },
            on: { closed: _vm.drawerClosed },
          })
        : _c("stage-table-modal", {
            attrs: {
              platformId: _vm.parent.id,
              open: _vm.drawerShow,
              "cur-row": _vm.curRow,
              tableData: _vm.dataSource,
              isForm: _vm.isNewForm,
              parent: _vm.parent,
            },
            on: { closed: _vm.drawerClosed },
          }),
      _c("sync-modal", {
        ref: "syncModal",
        attrs: {
          url: "/platform/stage/sync",
          platformId: _vm.parent.id,
          selectedRowKeys: _vm.selectedRowKeys,
          selectionRows: _vm.selectionRows,
        },
        on: { loadData: _vm.loadData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }