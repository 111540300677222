<template>
  <div :style="backCss">
    <div class="actions flex jc-end mb-4">
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        v-if="selectedRowKeys.length && parent.isTongChou"
        icon="delete"
        v-has="'platform:stage:button:delete'"
        >删除</a-button
      >
      <a-button
        type="primary"
        class="mr-3"
        @click="pSync"
        v-if="selectedRowKeys.length"
        :disabled="selectedRowKeys.length >= 2 || !selectionRows.length"
        icon="cloud"
        v-has="'system:rule:list:button:add'"
        >同步</a-button
      >
      <a-button
        type="primary"
        v-if="parent.isTongChou"
        @click="openAddForm"
        icon="plus"
        v-has="'platform:stage:button:add'"
        >添加</a-button
      >
    </div>
    <a-table
      style="border-top:1px solid rgb(232,232,232)"
      v-has="'platform:stage:page:view'"
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :dataSource="dataSource"
      :customRow="clickRow"
      :pagination="ipagination"
      :scroll="{ x: 1000, y: 500 }"
      :loading="loading"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 90 }"
      @change="handleTableChange"
    >
      <template slot="reversible" slot-scope="text, record">
        {{ record.reversible | bool }}
      </template>
      <template slot="evaluate" slot-scope="text, record">
        {{ (record.ruleId > 0) | bool }}
      </template>
      <template slot="rate" slot-scope="text, record">
        {{ record.rate | bool }}
      </template>
      <template slot="costTime" slot-scope="text, record">
        {{ record.predefinedTime != null || record.predefinedTime != undefined ? record.predefinedTime + ' 天' : '' }}
      </template>
      <template slot="ruleId" slot-scope="text, record">
        {{ record.ruleId > 0 ? '规则' + record.ruleId : '无' }}
      </template>
      <template slot="ruleList" slot-scope="text, record">
        <a-tag :color="one.hasUpdated ? 'green' : ''" v-for="one in record.ruleList" :key="one.id">{{
          one.name
        }}</a-tag>
      </template>
      <template slot="rejectList" slot-scope="text, record">
        <a-tag v-for="one in record.rejectList" :key="one.stageId">
          {{ one.stageName }}
        </a-tag>
      </template>
      <template slot="kpiList" slot-scope="text, record">
        <a-tag v-for="one in record.kpiList" :key="one.stageId">
          {{ one.stageName }}
        </a-tag>
      </template>
      <span @click.stop slot="applyTranslation" slot-scope="text, record">
        <!-- <span v-if="record.cardList">{{ record.canSync || record.canSync === null ? '允许' : '不允许' }}</span> -->
        <a-switch v-has="'platform:stage:button:add'" v-model="record.applyTranslation" @change="saveTableData" />
      </span>
      <span @click.stop slot="applyReview" slot-scope="text, record">
        <!-- <span v-if="record.cardList">{{ record.canSync || record.canSync === null ? '允许' : '不允许' }}</span> -->
        <a-switch v-has="'platform:stage:button:add'" v-model="record.applyReview" @change="saveTableData" />
      </span>
    </a-table>
    <stage-modal
      v-if="!isAdd"
      :platformId="parent.id"
      :open="drawerShow"
      :cur-row="curRow"
      :isForm="isNewForm"
      :parent="parent"
      @closed="drawerClosed"
    ></stage-modal>
    <stage-table-modal
      v-else
      :platformId="parent.id"
      :open="drawerShow"
      :cur-row="curRow"
      :tableData="dataSource"
      :isForm="isNewForm"
      :parent="parent"
      @closed="drawerClosed"
    ></stage-table-modal>
    <sync-modal
      ref="syncModal"
      url="/platform/stage/sync"
      :platformId="parent.id"
      :selectedRowKeys="selectedRowKeys"
      @loadData="loadData"
      :selectionRows="selectionRows"
    />
  </div>
</template>

<script>
import { checkPermission } from '@/utils/hasPermission'
import { postAction, getAction, deleteAction } from '@/api/manage'
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import syncModal from '@/components/psync-modal'
import StageModal from './stage-modal'
import StageTableModal from './stage-table-modal'
import { putAction } from '../../../../api/manage'
export default {
  mixins: [JeecgListMixin],
  components: {
    StageModal,
    StageTableModal,
    syncModal
  },
  props: {
    parent: {
      type: Object,
      default: () => ({})
    },
    tab: {
      type: String,
      default: '1'
    }
  },
  watch: {
    tab(n) {
      // this.loadData();
    }
  },
  data() {
    return {
      color: '',
      columns: [
        {
          title: '序号',
          dataIndex: 'sequenceNo',
          width: 100
        },
        {
          title: '阶段名称',
          dataIndex: 'stageName',
          width: 120
        },
        {
          title: '人员职位',
          width: 120,
          dataIndex: 'positionName'
        },
        /*         {
          title: '是否可逆',
          width: 120,
          dataIndex: 'reversible',
          scopedSlots: { customRender: 'reversible' }
        }, */
        {
          title: '可驳回阶段',
          scopedSlots: { customRender: 'rejectList' },
          dataIndex: 'rejectList',
          width: 120
        },
        {
          title: '可考核阶段',
          scopedSlots: { customRender: 'kpiList' },
          dataIndex: 'kpiList',
          width: 120
        },
        {
          title: '预计耗时',
          width: 100,
          scopedSlots: { customRender: 'costTime' },
          dataIndex: 'predefinedTime'
        },
        {
          title: () => {
            return this.checkPermission('platform:stage:button:add') ? (
              <div style="display:flex;align-items:center">
                <span>翻译系统</span>
                <a-switch
                  style="margin-left:10px"
                  ref="applyTranslationRef"
                  v-model={this.applyTranslation}
                  onChange={this.applyTranslationChange}
                />
              </div>
            ) : (
              '翻译系统'
            )
          },
          width: 150,
          scopedSlots: { customRender: 'applyTranslation' },
          dataIndex: 'applyTranslation'
        },
        {
          title: () => {
            return this.checkPermission('platform:stage:button:add') ? (
              <div style="display:flex;align-items:center">
                <span>查稿系统</span>
                <a-switch style="margin-left:10px" v-model={this.applyReview} onChange={this.applyReviewChange} />
              </div>
            ) : (
              '翻译系统'
            )
          },
          width: 150,
          scopedSlots: { customRender: 'applyReview' },
          dataIndex: 'applyReview'
        },
        {
          title: '规则',
          dataIndex: 'ruleList',
          scopedSlots: { customRender: 'ruleList' }
        }
        // {
        //   title: '是否考核',
        //   dataIndex: 'evaluate',
        //   scopedSlots: { customRender: 'evaluate' },
        // },
        // {
        //   title: '是否评级',
        //   scopedSlots: { customRender: 'rate' },
        //   dataIndex: 'rate',
        // },
        // {
        //   title: '价格',
        //   scopedSlots: { customRender: 'ruleId' },
        //   dataIndex: 'ruleId',
        // },
      ],
      isDele: 'YES',
      applyTranslation: false,
      applyReview: false
    }
  },
  methods: {
    loadData(arg, id, callback) {
      if (!this.url.list) {
        this.$message.error('请设置url.list属性!')
        return
      }
      //加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      var params = this.getQueryParams() //查询条件
      if (this.id) params.id = this.id
      if (id) params.id = id
      this.loading = true
      params.current = params.pageNo
      params.size = params.pageSize
      getAction(this.url.list, params).then(res => {
        console.log('JeecgListMixin loadData getAction over', { res })
        if (res.success) {
          this.selectedRowKeys = []
          this.selectionRows = []
          //update-end---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------

          // 字体预览需要往head里插入css样式
          if (this.isInserstCss) {
            batchStyleInsertHeader(res.data.records || res.data)
            setTimeout(() => {
              this.dataSource = res.data.records || res.data
              if (res.data.total) {
                this.ipagination.total = res.data.total - 0
              } else {
                this.ipagination.total = 0
              }
              this.$emit('loadData', this.dataSource)
            }, 0)
          } else {
            this.dataSource = res.data.records || res.data
            if (res.data.total) {
              this.ipagination.total = res.data.total - 0
            } else {
              this.ipagination.total = 0
            }
            this.dataSource.forEach(item => {
              if (item.applyTranslation) {
                item.applyTranslation = true
              } else {
                item.applyTranslation = false
              }
              if (item.applyReview) {
                item.applyReview = true
              } else {
                item.applyReview = false
              }
            })
            if (this.dataSource.every(item => item.applyTranslation)) {
              this.applyTranslation = true
            }
            if (this.dataSource.every(item => item.applyReview)) {
              this.applyReview = true
            }
            this.$emit('loadData', this.dataSource)
          }
        }
        if (res.code === 510) {
          this.$message.warning(res.message)
        }
        this.loading = false
      })
    },
    async applyTranslationChange(e) {
      this.dataSource.forEach(item => {
        item.applyTranslation = e
      })
      let arr = JSON.parse(JSON.stringify(this.dataSource))
      arr.forEach(item => {
        if (item.applyTranslation) {
          item.applyTranslation = 1
        } else {
          item.applyTranslation = 0
        }
        if (item.applyReview) {
          item.applyReview = 1
        } else {
          item.applyReview = 0
        }
      })
      const res = await putAction('/platform/enable/stage/system', arr)
      if (res.code == 200) {
        this.$message.success('保存成功')
        this.dataSource.forEach(item => {
          if (item.applyTranslation) {
            item.applyTranslation = true
          } else {
            item.applyTranslation = false
          }
          if (item.applyReview) {
            item.applyReview = true
          } else {
            item.applyReview = false
          }
        })
        if (this.dataSource.every(item => item.applyTranslation)) {
          this.applyTranslation = true
        } else {
          this.applyTranslation = false
        }
        if (this.dataSource.every(item => item.applyReview)) {
          this.applyReview = true
        } else {
          this.applyReview = false
        }
      } else {
        this.$message.error('保存失败')
      }
    },
    async applyReviewChange(e) {
      this.dataSource.forEach(item => {
        item.applyReview = e
      })
      let arr = JSON.parse(JSON.stringify(this.dataSource))
      arr.forEach(item => {
        if (item.applyTranslation) {
          item.applyTranslation = 1
        } else {
          item.applyTranslation = 0
        }
        if (item.applyReview) {
          item.applyReview = 1
        } else {
          item.applyReview = 0
        }
      })
      const res = await putAction('/platform/enable/stage/system', arr)
      if (res.code == 200) {
        this.$message.success('保存成功')
        this.dataSource.forEach(item => {
          if (item.applyTranslation) {
            item.applyTranslation = true
          } else {
            item.applyTranslation = false
          }
          if (item.applyReview) {
            item.applyReview = true
          } else {
            item.applyReview = false
          }
        })
        if (this.dataSource.every(item => item.applyTranslation)) {
          this.applyTranslation = true
        } else {
          this.applyTranslation = false
        }
        if (this.dataSource.every(item => item.applyReview)) {
          this.applyReview = true
        } else {
          this.applyReview = false
        }
      } else {
        this.$message.error('保存失败')
      }
    },
    async saveTableData() {
      let arr = JSON.parse(JSON.stringify(this.dataSource))
      arr.forEach(item => {
        if (item.applyTranslation) {
          item.applyTranslation = 1
        } else {
          item.applyTranslation = 0
        }
        if (item.applyReview) {
          item.applyReview = 1
        } else {
          item.applyReview = 0
        }
      })
      const res = await putAction('/platform/enable/stage/system', arr)
      if (res.code == 200) {
        this.$message.success('保存成功')
        this.dataSource.forEach(item => {
          if (item.applyTranslation) {
            item.applyTranslation = true
          } else {
            item.applyTranslation = false
          }
          if (item.applyReview) {
            item.applyReview = true
          } else {
            item.applyReview = false
          }
        })
        if (this.dataSource.every(item => item.applyTranslation)) {
          this.applyTranslation = true
        } else {
          this.applyTranslation = false
        }
        if (this.dataSource.every(item => item.applyReview)) {
          this.applyReview = true
        } else {
          this.applyReview = false
        }
      } else {
        this.$message.error('保存失败')
      }
    },
    checkPermission(suc) {
      return checkPermission(suc)
    },
    batchDel: function() {
      console.log('JeecgListMixin batchDel')
      if (!this.url.deleteBatch) {
        this.$message.error('请设置url.deleteBatch属性!')
        return
      }
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning('请选择一条记录！')
        return
      } else {
        var ids = this.selectedRowKeys.join(',')
        var that = this

        let url = that.url.deleteBatch
        // 增加作品管理的产品id
        if (this.productionId) {
          url = `${url}/${this.productionId}`
        } else if (this.platformId) {
          url = `${url}/${this.platformId}`
        }

        this.$confirm({
          title: '确认删除',
          content: h => {
            return (
              <div>
                <div style={{ paddingBottom: '10px' }}>
                  是否删除平台下所有作品的选中阶段?
                  <br />
                  如果选择【是】，则作品中相关任务会一起删除，且无法恢复，请谨慎操作
                </div>
                <a-radio-group v-model={this.isDele}>
                  <a-radio value="YES">是</a-radio>
                  <a-radio value="NO">否</a-radio>
                </a-radio-group>
              </div>
            )
          },
          onOk: function() {
            that.loading = true
            deleteAction(url, { ids: ids, isIncludeProduct: that.isDele == 'YES' })
              .then(res => {
                if (res.success) {
                  that.$message.success(res.message || res.msg ? '删除成功' : '')
                  if (that.$refs.newTable) {
                    that.$refs.newTable.loadData()
                  } else {
                    that.loadData()
                  }
                  that.onClearSelected()
                  that.$emit('loadData')
                } else {
                  that.$message.warning(res.message || res.msg)
                }
              })
              .finally(() => {
                that.loading = false
              })
          }
        })
      }
    }
  },
  created() {},
  mounted() {
    setTimeout(() => {
      for (let css of document.styleSheets) {
        for (let rule of css.cssRules) {
          if (rule.cssText?.includes('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected')) {
            this.color = rule.style.backgroundColor
          }
        }
      }
    }, 500)
  },
  computed: {
    url: {
      get: function() {
        return {
          list: `/platform/get_platform_stage?id=${this.parent.id}`,
          deleteBatch: `/platform/delete_stage_batch/${this.parent.id}`
        }
      }
    },
    backCss() {
      let color = this.color
      let backColor = this.$store.state.app.color
      return {
        '--back-color': color,
        '--theme-color': backColor
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-table-row:hover {
  .ant-table-row-cell-break-word {
    background-color: var(--back-color) !important;
  }
  td {
    background-color: var(--back-color) !important;
  }
}
</style>
